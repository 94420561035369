import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarMenu = _resolveComponent("SidebarMenu")!
  const _component_score_talk_overlay = _resolveComponent("score-talk-overlay")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_IonSplitPane = _resolveComponent("IonSplitPane")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, { class: "themeEmmyScore" }, {
    default: _withCtx(() => [
      _createVNode(_component_IonSplitPane, {
        "content-id": "main-content",
        when: "(min-width: 1920px)"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SidebarMenu),
          (_ctx.isTalkEnabled === true)
            ? (_openBlock(), _createBlock(_component_score_talk_overlay, { key: 0 }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_ion_router_outlet, {
            id: "main-content",
            animated: false
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}