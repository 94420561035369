<template>
  <IonApp class="themeEmmyScore">
    <IonSplitPane content-id="main-content" when="(min-width: 1920px)">
      <SidebarMenu />
      <score-talk-overlay v-if="isTalkEnabled === true" />
      <ion-router-outlet id="main-content" :animated="false"> </ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script lang="ts">
  import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
  import { defineComponent } from 'vue';
  import SidebarMenu from '@/components/layout/Sidebar.vue';
  import ScoreTalkOverlay from '@/views/talk/ScoreTalkOverlay.vue';

  export default defineComponent({
    name: 'App',
    components: {
      ScoreTalkOverlay,
      IonApp,
      IonRouterOutlet,
      IonSplitPane,
      SidebarMenu,
    },
    computed: {
      isTalkEnabled: function () {
        return process.env.VUE_APP_ENABLE_TALK == 'true' ? true : false;
      },
    },
  });
</script>

<style scoped lang="scss">
  html {
    overflow: auto;
  }

  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  ion-split-pane {
    --side-max-width: 15%;
    --border: none;
  }

  ion-router-outlet:deep .ion-page {
    background: var(--background);
    background-size: cover;

    ion-content {
      --background: none;
      --offset-top: 0px !important;
    }
  }

  ion-router-outlet.login:deep .ion-page {
    background: var(--background);
    background-size: cover;

    ion-content {
      --background: none;

      --webkit-scrollbar {
        width: 8px;
      }
      --webkit-scrollbar-track {
        background-color: transparent;
        margin-right: 60px;
        border-radius: 10px;
      }
      --webkit-scrollbar-thumb {
        background-color: #ededf0;
        border-radius: 10px;
        height: 140px;
      }
    }
  }
</style>
