import { Ref, ref } from 'vue';
import { api } from '@/config/AxiosConfig';
import { UserProfile } from '@/model/user-profile.model';
import { Permissions } from '@/model/permission.model';

const userProfile: Ref<UserProfile | undefined> = ref(undefined);
export function useAuthGlobalState() {
  /**
   * Loads the user profile from the backend and sets it in the  global state.
   * Called in router.beforeEach()
   */
  const loadUserProfile = async (forceReload = false) => {
    if (!userProfile.value || forceReload) {
      const response = await api.get<UserProfile>('/v1/permissions/user-profile?forceReload=' + forceReload);
      if (response.status !== 200) {
        console.error('Error fetching user permissions', response);
        return;
      }
      userProfile.value = response.data;
    }
  };

  const checkPermission = (permission: Permissions): boolean => {
    return userProfile?.value?.permissions?.includes(Permissions[permission]) ?? false;
  };

  const hasNoPermission = (permission: Permissions): boolean => {
    return !checkPermission(permission);
  };

  const checkAllPermissions = (...permissions: Permissions[]): boolean => {
    if (!permissions || permissions.length === 0) {
      return false;
    }

    let hasPermission = false;
    for (const p of permissions) {
      hasPermission = checkPermission(p);
    }
    return hasPermission;
  };

  const checkProjectPermission = (projectId: string | undefined, permission: Permissions): boolean => {
    if (!projectId) {
      return false;
    }
    return (
      userProfile?.value?.projects.find(p => p.id === projectId)?.permissions?.includes(Permissions[permission]) ??
      false
    );
  };

  const hasNoProjectPermission = (projectId: string | undefined, permission: Permissions): boolean => {
    return !checkProjectPermission(projectId, permission);
  };

  return {
    userProfile,
    loadUserProfile,
    checkPermission,
    hasNoPermission,
    checkAllPermissions,
    checkProjectPermission,
    hasNoProjectPermission,
  };
}
