import { api, noSpinnerToken } from '@/config/AxiosConfig';
import { Job, JobLists, JobState } from '@/model/Job.model';
import { StrictUseAxiosReturn, useAxios } from '@vueuse/integrations/useAxios';
import { computed, Ref, ref } from 'vue';
import { Group } from '@/model/Group.model';
import { filter } from 'cypress/types/minimatch';
import { JobFilters } from '@/views/project/model/JobFilters.model';

// GLOBAL STATE - used by main projects list view and sidebar
const { data, execute }: StrictUseAxiosReturn<JobLists> & PromiseLike<StrictUseAxiosReturn<JobLists>> =
  useAxios<JobLists>('', api, { immediate: false });

const draftJobs: Ref<Job[]> = ref([]);
const releaseCandidateJobs: Ref<Job[]> = ref([]);
const publishedJobs: Ref<Job[]> = ref([]);
const publishedReviewingJobs: Ref<Job[]> = ref([]);
const closedJobs: Ref<Job[]> = ref([]);
const closedReviewingJobs: Ref<Job[]> = ref([]);
const terminatedJobs: Ref<Job[]> = ref([]);
const currentStateJobs: Ref<Job[]> = ref([]);
const groups: Ref<Group[]> = ref([]);

export function useProjectsListGlobalState() {
  /**
   * Always load jobs from backend.
   */
  const jobFilters = ref<JobFilters>({});
  const loadJobs = async (loadForState: JobState | null, pageNumber: number, noSpinner: boolean) => {
    await execute(
      `/v1/projectLists${
        loadForState ? '?findByState=' + loadForState : ''
      }&paginationUse=true&paginationStartIndex=${pageNumber}&paginationPageSize=20${
        noSpinner ? '&' + noSpinnerToken : ''
      }${jobFilters.value.name && jobFilters.value.name.length > 0 ? `&findByName=${jobFilters.value.name}` : ''}${
        jobFilters.value.selectedLabels && jobFilters.value.selectedLabels.length > 0
          ? `&findByLabels=${jobFilters.value.selectedLabels}`
          : ''
      }${
        jobFilters.value.selectedOrganizations && jobFilters.value.selectedOrganizations.length > 0
          ? `&findByOrganizations=${jobFilters.value.selectedOrganizations}`
          : ''
      }${
        jobFilters.value.selectedHiringManagers && jobFilters.value.selectedHiringManagers.length > 0
          ? `&findByHiringManagers=${jobFilters.value.selectedHiringManagers}`
          : ''
      }`,
    );
    calculateJobs();
  };

  /**
   * Load jobs from backend only if not already loaded.
   */
  const loadJobsIfNotLoaded = async () => {
    if (!data.value) {
      await execute();
      calculateJobs();
    }
  };

  const findJobs = (findBy: string) => {
    execute(`/v1/projects/find?findBy=${findBy}`);
  };

  const deleteJob = async (id: string) => {
    const { error } = await useAxios<void>(`/v1/projects/${id}`, { method: 'DELETE' }, api);
    if (!error.value) {
      if (data.value?.default) {
        data.value.default = data.value.default.filter(job => job.id !== id);
      }

      if (data.value?.reviewing) {
        data.value.reviewing = data.value.reviewing.filter(job => job.id !== id);
      }

      if (data.value?.hm) {
        data.value.hm = data.value.hm.filter(job => job.id !== id);
      }

      if (data.value?.owner) {
        data.value.owner = data.value.owner.filter(job => job.id !== id);
      }

      if (currentStateJobs.value && currentStateJobs.value.length > 0) {
        currentStateJobs.value = currentStateJobs.value.filter(job => job.id !== id);
      }
    }
    return error;
  };

  const changeJobStatus = async (id: string, status: JobState, loadForState: JobState | null) => {
    const { error } = await useAxios<void>(`/v1/projects/${id}/state`, { method: 'PUT', data: { state: status } }, api);
    if (!error.value) {
      loadJobs(loadForState, 1, false);
    }
    return error;
  };

  const calculateJobs = () => {
    const defaultJobs = data.value?.default ?? [];
    const hmJobs = data.value?.hm ?? [];
    const ownerJobs = data.value?.owner ?? [];
    const reviewingJobs = data.value?.reviewing ?? [];
    const jobs = defaultJobs.concat(hmJobs).concat(ownerJobs);
    const groupData = data.value?.groups ?? [];

    currentStateJobs.value = jobs;

    draftJobs.value = jobs.filter((job: Job) => job.state === JobState.Draft);
    releaseCandidateJobs.value = jobs.filter((job: Job) => job.state === JobState.ReleaseCandidate);
    publishedJobs.value = jobs.filter((job: Job) => job.state === JobState.Published);
    closedJobs.value = jobs.filter((job: Job) => job.state === JobState.Closed);
    terminatedJobs.value = jobs.filter((job: Job) => job.state === JobState.Terminated);

    publishedReviewingJobs.value = reviewingJobs.filter(job => job.state === JobState.Published);
    closedReviewingJobs.value = reviewingJobs.filter(job => job.state === JobState.Closed);

    groups.value = groupData;
  };

  return {
    loadJobs,
    loadJobsIfNotLoaded,
    findJobs,
    data,
    draftJobs,
    releaseCandidateJobs,
    publishedJobs,
    closedJobs,
    groups,
    publishedReviewingJobs,
    closedReviewingJobs,
    terminatedJobs,
    currentStateJobs,
    deleteJob,
    changeJobStatus,
    jobFilters,
  };
}
