/**
 * This values will be replaced by CI pipeline
 */
export const GIT_COMMIT_ID = 'c33dcaf06ee1f8d0e7eef6cf1aa2cb4c0e3230aa';
export const BUILD_DATE = 'Wed Feb 28 09:38:10 UTC 2024';
export const VERSION = '1.4.0';

const CURRENT_ENV = '516745382884';
export const ENVIRONMENT = (): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return CURRENT_ENV === '510552048455' ? 'TEST' : 'PROD';
};
